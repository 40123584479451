<template>
  <b-modal
    ref="modal"
    id="add-product-for-billingsheet"
    title="Ajout un produit"
    ok-title="Sauvegarder"
    cancel-variant="outline-secondary"
    cancel-title="Annuler"
    @ok.prevent="validationForm"
    no-close-on-backdrop
  >
    <validation-observer ref="simpleRules">
      <form>
        <div class="form-group">
          <label>Produit</label>
          <validation-provider
            #default="{ errors }"
            name="produit"
            rules="required"
          >
            <v-select
              id="group-type"
              :options="extractProducts(currentProducts)"
              label="fullname"
              @input="selectedProd = $event"
              :value="selectedProd"
              :reduce="(option) => option"
              :class="{ 'is-invalid': errors.length > 0 }"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </div>
      </form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { reactive, ref } from "@vue/composition-api/dist/vue-composition-api";
import BCardCode from "@core/components/b-card-code";
import {
  BButton,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadio,
  BFormTextarea,
  BInputGroup,
  BInputGroupPrepend,
  BModal,
  BRow,
  BTab,
  BTabs,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { GroupProductService } from "../../services/GroupProductService";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required } from "@validations";
const groupProduct = new GroupProductService();
export default {
  name: "AddProductBillingSheet",
  components: {
    BCardCode,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BCardText,
    BFormGroup,
    vSelect,
    BRow,
    BCol,
    BInputGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BTabs,
    BFormFile,
    BTab,
    BFormInvalidFeedback,
    BFormRadio,
    ValidationProvider,
    ValidationObserver,
  },
  props: ["currentProducts"],
  setup(props, context) {
    const route = context.root.$route;
    const productTrims = ref([]);
    let selectedProd = null;
    let fetchProduct = async (groupId) => {
      groupProduct.getProducts(groupId).then((response) => {
        productTrims.value = response.data;
      });
    };
    fetchProduct(route.params.group_id);
    const extractProducts = (currentProducts) => {
      const joinWithoutDuplicates = (A, B) => {
        const a = new Set(A.map((x) => x.id));
        const b = new Set(B.map((x) => x.id));
        return [
          ...A.filter((x) => !b.has(x.id)),
          ...B.filter((x) => !a.has(x.id)),
        ];
      };
      const output = joinWithoutDuplicates(currentProducts, productTrims.value);
      output.forEach((item) => {
        if (item.pc_voyages_product_desc) {
          item.fullname = `${item.name} - ${item.pc_voyages_product_desc}`;
        } else {
          item.fullname = `${item.name}`;
        }
      });
      return output;
    };
    const submitProductBillingSheet = (product) => {
      context.emit("submitProductBillingSheet", product);
    };
    return {
      extractProducts,
      productTrims,
      selectedProd,
      submitProductBillingSheet,
    };
  },
  data() {
    return {
      required,
    };
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.$refs["modal"].hide();
          this.submitProductBillingSheet(this.selectedProd);
          this.selectedProd = null;
        }
      });
    },
    getProductName(product) {
      return product.name;
    },
  },
};
</script>

<style scoped>
.vs__fade-enter-active,
.vs__fade-leave-active {
  transition: none;
}
</style>
