<template>
  <div>
    <b-card border-variant="primary" header-tag="header" class="mt-2" >
      <template #header >
          <h4>
            {{billingProduct.name}}
            <template v-if="billingProduct.pc_voyages_product_desc">
              - {{billingProduct.pc_voyages_product_desc}}
            </template>
          </h4>

          <!-- <b-button v-if='isDisabled' variant="outline-primary" @click="switchIsDisabled(false)">Modifier</b-button>
          <b-button v-if='!isDisabled' variant="success" @click="switchIsDisabled(true)">Sauvegarder</b-button> -->
          <b-button variant="danger" @click="$emit('remove', billingProduct)" v-if="canModified" >Retirer</b-button>
      </template>
      <b-row class="">
        <b-col cols='12'>
          <h1 class="section-label mx-0 mb-2">
            Montant du produit
          </h1>
              <b-row class="mt-1">
                <b-col cols="6">
                  <h5 class="mb-1">Détail du prix de vente </h5>

                  <b-list-group>
                      <b-list-group-item>
                        Montant total avant taxes
                        <b-form-input
                          v-if="!isDisabled"
                          size="sm"
                          :value="billingProduct.amount"
                          style="width:10em; float:right"
                        />
                        <span v-if="isDisabled" style="float:right">$ {{billingProduct.amount}}</span>
                      </b-list-group-item>
                      <b-list-group-item>
                        Taxes
                        <b-form-input
                          v-if="!isDisabled"
                          size="sm"
                          :value="billingProduct.taxes"
                          style="width:10em; float:right"
                        />
                        <span v-if="isDisabled" style="float:right">$ {{billingProduct.taxes}}</span></b-list-group-item>
                      <b-list-group-item>
                        Autres taxes
                        <b-form-input
                          v-if="!isDisabled"
                          size="sm"
                          :value="billingProduct.other_taxes"
                          style="width:10em; float:right"
                        />
                        <span v-if="isDisabled" style="float:right">$ {{billingProduct.other_taxes}}</span></b-list-group-item>
                      <b-list-group-item>
                        TPS (sur montant avant taxes)
                        <b-form-input
                          v-if="!isDisabled"
                          size="sm"
                          :value="billingProduct.gst"
                          style="width:10em; float:right"
                        />
                        <span v-if="isDisabled" style="float:right">$ {{billingProduct.gst}}</span></b-list-group-item>
                      <b-list-group-item>
                        TVQ (sur montant avant taxes)
                        <b-form-input
                          v-if="!isDisabled"
                          size="sm"
                          :value="billingProduct.qst"
                          style="width:10em; float:right"
                        />
                        <span v-if="isDisabled" style="float:right">$ {{billingProduct.qst}}</span></b-list-group-item>
                      <b-list-group-item class="dark-line-item">Montant Total Brut (Prix de vente)
                        <b-form-input
                          v-if="!isDisabled"
                          size="sm"
                          :value="billingProduct.gross_total"
                          style="width:10em; float:right"
                        />
                        <span v-if="isDisabled" style="float:right">$ {{billingProduct.gross_total}}</span>
                      </b-list-group-item>

                      <b-list-group-item class="dark-line-item">Montant Total Net (Prix de vente au NET)
                        <b-form-input
                          v-if="!isDisabled"
                          size="sm"
                          :value="billingProduct.net_total"
                          style="width:10em; float:right"
                        />
                        <span v-if="isDisabled" style="float:right">$ {{billingProduct.net_total}}</span>
                        </b-list-group-item>

                    </b-list-group>
                </b-col>
                <b-col cols="6">
                  <h5 class="mb-1">{{productLabels[billingProduct.type].TITLE}}</h5>

                  <b-list-group>
                      <b-list-group-item>{{productLabels[billingProduct.type].MAIN_LINE}}
                          <b-form-input
                            v-if="!isDisabled"
                            size="sm"
                            :value="billingProduct.commission"
                            style="width:10em; float:right"
                          />
                          <span v-if="isDisabled" style="float:right">{{billingProduct.commission}}</span>
                     </b-list-group-item>
                      <b-list-group-item>TPS
                        <b-form-input
                            v-if="!isDisabled"
                            size="sm"
                            :value="billingProduct.commission_gst"
                            style="width:10em; float:right"
                          />
                          <span v-if="isDisabled" style="float:right">{{billingProduct.commission_gst}}</span>
                      </b-list-group-item>
                      <b-list-group-item>TVQ
                          <b-form-input
                            v-if="!isDisabled"
                            size="sm"
                            :value="billingProduct.commission_qst"
                            style="width:10em; float:right"
                          />
                          <span v-if="isDisabled" style="float:right">{{billingProduct.commission_qst}}</span>
                      </b-list-group-item>
                      <b-list-group-item class="dark-line-item">{{productLabels[billingProduct.type].BOTTOM_LINE}}
                          <b-form-input
                            v-if="!isDisabled"
                            size="sm"
                            :value="billingProduct.commission_total"
                            style="width:10em; float:right"
                          />
                          <span v-if="isDisabled" style="float:right">{{billingProduct.commission_total}}</span>
                      </b-list-group-item>

                  </b-list-group>



                </b-col>
              </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols='12'>
          <h1 class="section-label mx-0 mt-1 mb-2">
            Dépot & Note
          </h1>
        </b-col>
        <b-col cols='3'>
          <div class="">
            <b-form-group
              label="Montant du dépot"
              label-for="transport-options"
            >
              <b-form-input
                :disabled="isDisabled"
                size="sm"
                placeholder="Escapade à Paris"
                :value="billingProduct.required_deposit_amount"
              />
            </b-form-group>
          </div>
        </b-col>
        <b-col cols='3'>
          <div class="">
            <b-form-group
              label="Comment payer dépôt "
              label-for="transport-options"
            >
              <b-form-input
                :disabled="isDisabled"
                size="sm"
                placeholder="Escapade à Paris"
                :value="billingProduct.payment_instruction"
              />
            </b-form-group>
          </div>
        </b-col>
        <b-col cols='6'>
          <div class="">
            <b-form-group
              label="Note du produit"
              label-for="transport-options"
            >
              <b-form-textarea
                :disabled="isDisabled"
                rows="1"
                max-rows="1"
                :value="billingProduct.notes"
              ></b-form-textarea>
            </b-form-group>
          </div>
        </b-col>
      </b-row>

    </b-card>

  </div>
</template>

<script>
import { BListGroup, BListGroupItem, BFormGroup,BFormInput,BFormTextarea, BTabs, BTab, BRow, BCol, BCard, BCardBody, BCardText, BButton,BButtonGroup,BCollapse,VBToggle, BIconFileEarmarkEasel } from 'bootstrap-vue'

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardBody,
    BButton,
    BCollapse,
    BButtonGroup,
    BFormGroup, BFormInput,BFormTextarea, BListGroup, BListGroupItem
  },
  props: {
    billingProduct: Object,
    canModified: {
      type: Boolean,
      required: false,
    }
  },
  data() {
    return {
      isDisabled: true,
      currentGroup: {},
      productLabels: {
        "PRODUCT": {
          "TITLE": "Commission",
          "MAIN_LINE": "Commission",
          "BOTTOM_LINE": "Total commission à recevoir"
        },
        "ADMIN_FEE_IN": {
          "TITLE": "Frais administratif",
          "MAIN_LINE": "Montant à recevoir",
          "BOTTOM_LINE": "Total montant à recevoir"
        },
        "ADMIN_FEE_OUT": {
          "TITLE": "Frais administratif",
          "MAIN_LINE": "Montant payable à TDC (état de compte)",
          "BOTTOM_LINE": "Total montant payable à TDC (état de compte)"
        },

      }

    }
  },
  directives: {
    'b-toggle': VBToggle,
  },
  methods: {
    switchIsDisabled(value) {
      this.isDisabled = value;
    }

  }
}
</script>

<style>
 [dir] .form-control:disabled, [dir] .form-control[readonly] {
    background-color: #d8d6de;
  }
</style>
